
.color-white {
    color: #fff !important;
}
.not-start-script {
    position: relative;
    width: 100%;
    height: 100%;
    .script-containers {
        position: relative;
        width: 100%;
        height: fit-content;
    }
    .line {
        position: absolute;
        top: 0;
        left: 15%;
        bottom: 20px;
        width: 1px;
        background-color: #444797;
    }
    .total-list {
        .total-item {
            display: flex;
            flex-direction: column;
            padding-bottom: 20px;
            .title-box {
                position: relative;
                display: flex;
                align-items: center;
                align-self: flex-end;
                width: 85%;
                margin-bottom: 15px;
                font-size: 18px;
                transform: translateX(-13px);
                .sequential-num {
                    width: 26px;
                    height: 26px;
                    margin-right: 10px;
                    line-height: 26px;
                    text-align: center;
                    border-radius: 50%;
                    background-color: #bfbfbf;
                }
            }
            .content {
                display: flex;
                width: 100%;
                .script-snippet-box {
                    width: 100%;
                    box-sizing: border-box;
                    /* opacity: 0.6; */
                    line-height: 30px;
                    color: #8e8e97;
                    overflow: hidden;
                    transition: color .3s;
                    .script-snippet-item {
                        display: flex;
                        width: 100%;
                        overflow: hidden;
                        
                        
                        .time {
                            box-sizing: border-box;
                            width: 15%;
                            padding-right: 15px;
                            text-align: end;
                            overflow: hidden;
                            &.icon {
                                display: flex;
                                justify-content: flex-end;
                                padding-right: 0;
                                color: #fff;
                                overflow: unset;
                                + .text {
                                    color: #fff;
                                    font-size: 18px;
                                }
                                .iconfont {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    width: 30px;
                                    height: 30px;
                                    border-radius: 37%;
                                    background-color: #f49d19;
                                    transform: translateX(50%);
                                    z-index: 9;
                                }
                                &.goods .iconfont {
                                    border-radius: 50%;
                                    background-color: #bfbfbf;
                                }
                            }
                        }
                        .text {
                            display: flex;
                            flex-direction: column;
                            box-sizing: border-box;
                            width: 85%;
                            padding: 0 30px;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
    }
}
