
.live-script {
    box-sizing: border-box;
    //flex: 6;
    width: 35%;
    font-size: 14px;
    color: #fff;
    background-color: #0c0e3f;
    .container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        .head-box {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100px;
            width: 100%;
            font-size: 24px;
            .title {
                margin: 0 24px;
                margin-top: -2px;
            }
            .decorate {
                width: 41px;
                height: 15px;
                vertical-align: middle;
            }
        }
        .content {
            position: relative;
            flex: 1;
            display: flex;
            justify-content: center;
            overflow: hidden;
            .script-content {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .live-ing-script {
                box-sizing: border-box;
                display: flex;
                align-items: center;
                width: 85%;
                height: 100%;
                font-size: 24px;
                line-height: 72px;
                letter-spacing: 1px;
                word-break: break-all;
                overflow: hidden;
                .text {
                    /* transform: translateY(-30px); */
                }
            }
            .empty {
                position: absolute;
                top: 40%;
                font-size: 20px;
                color: #aaa;
            }
        }
    }
}
.data-board {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 22px;
    width: 104px;
    height: 93px;
    color: #fff;
    font-size: 14.5px;
    font-weight: 700;
    background: url("../../../assets/images/live/flag.png") no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
}
::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
}
::v-deep .el-scrollbar__view {
    display: flex;
    min-width: 100%;
    min-height: 100%;
}
