
.bottom-action {
    position: relative;
    box-sizing: border-box;
    display: flex;
    z-index: 99;
    ::v-deep .input {
        width: 254px;
        height: 46px;
        input {
            height: 100%;
            padding: 0 12px;
            color: #fff;
            background-color: rgba(0, 0, 0, 0.4);
            border: none;
            border-radius: 23px;
        }
    }
    .actions {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .action-item {
            width: 46px;
            height: 46px;
            margin-left: 22px;
            font-size: 30px;
            line-height: 46px;
            text-align: center;
            color: #999;
            border-radius: 50%;
            background-color: #fff;
            user-select: none;
            cursor: pointer;
        }
        .action-item:first-child {
            font-size: 24px;
            text-indent: 2px;
        }
        .action-item:last-child {
            color: #4b45ff;
        }
    }
}
::v-deep .input input::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #fff;
}

::v-deep .input input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #fff;
}

::v-deep .input input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #fff;
}

::v-deep .input input:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #fff;
}
